export const LAGUNA_DE_ACULEO = "Laguna de Aculeo";
export const LO_PINTO = "Lo Pinto";
export const CERRO_SAN_FRANCISCO = "Cerro San Francisco";
export const LLANOS_DE_CALEU = "Llanos de Caleu";
export const SAN_JOSE_GUAYACAN = "San José Guayacán";

export const ESTACIONES_METEOROLOGICAS = [
  "Fundo Alhué",
  "Curacaví Aeródromo",
  "Patagüilla",
  "Pudahuel Santiago",
  LAGUNA_DE_ACULEO,
  LO_PINTO,
  CERRO_SAN_FRANCISCO,
  LLANOS_DE_CALEU,
  SAN_JOSE_GUAYACAN,
];

export const ESTACIONES_COMUNIDAD_CONTRA_EL_FUEGO = [
  LAGUNA_DE_ACULEO,
  LO_PINTO,
  CERRO_SAN_FRANCISCO,
  LLANOS_DE_CALEU,
  SAN_JOSE_GUAYACAN,
];
